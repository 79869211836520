import Commons from '@/components/shared/Helpers/commons'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { PodoEvaluationModel } from '@/models/podo-evaluation-model'
import { PodoService } from '@/services/patient-podo-service'
import { Component, Vue } from 'vue-property-decorator'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import Alert from '@/components/shared/Alert.vue'
import { ErrorService } from '@/services/error.service'
import { AxiosResponse } from 'axios'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'

@Component({
  components: {
    Confirm,
    Alert
  }
})

export default class EvaluationsMesures extends Vue {
  private podoService = PodoService.getInstance()
  private selectedItem = {}

  public headers = [
    { text: 'Date', value: 'evaluationDate' },
    { text: 'Prochaine évaluation', value: 'nextEvaluationDate' },
    { text: 'Observation', value: 'observation' },
    { text: 'Créé par', value: 'createdBy' },
    { text: '', value: 'actions', sortable: false, align: 'right' }
  ]

  public selectedEvaluationPodo = ''
  public showConfirm = false
  public errorMessages: string[] = []
  public evaluationsList: PodoEvaluationModel[] = []
  public dossierId = ''
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public mounted () {
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (cachedDossier) {
      const selectedDossier = JSON.parse(cachedDossier) as dossierPatientModel
      this.dossierId = selectedDossier.guid
      this.ensureUserNavTitle(selectedDossier)
      this.getEvaluations(this.dossierId)
    }
  }

  private getEvaluations (dossierId: string) {
    this.podoService.GetDossierPodoEvaluations(dossierId).then((podos) => {
      this.evaluationsList = podos
    })
  }

  public ensureUserNavTitle (selectedDossier: dossierPatientModel) {
    if (selectedDossier?.guid) {
      Commons.updateDossierName()
    }
  }

  public editEvaluation (item: PodoEvaluationModel) {
    const path = `/patient/consultation/podo/evaluations/${this.dossierId}/${item.id}`
    this.$router.push(path)
  }

  public displayDeleteConfirmDialog (evaluation: PodoEvaluationModel) {
    this.showConfirm = true
    this.selectedItem = evaluation
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      const results = await this.podoService.deleteEvaluation((this.selectedItem as PodoEvaluationModel).id)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })

      if ((results as AxiosResponse<any>)?.status === 200) {
        this.errorMessages = []
        this.getEvaluations(this.dossierId)
      }
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
