import { Component, Vue } from 'vue-property-decorator'
import Alert from '@/components/shared/Alert.vue'
import { ErrorService } from '@/services/error.service'
import { AxiosResponse } from 'axios'
import { dossierPatientModel } from '@/models/dossier-response-model'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import { AuthService } from '@/services/auth-service'
import { PodiatryObservationModel } from '@/models/podiatry-observation-model'
import EmptyTimeLine from '@/components/shared/EmptyTimeLine.vue'
import Commons from '@/components/shared/Helpers/commons'
import { PodoService } from '@/services/patient-podo-service'

@Component({
  components: {
    Confirm,
    Alert,
    EmptyTimeLine
  }
})
export default class Observations extends Vue {
  private podoService = PodoService.getInstance()
  private userService = AuthService.getInstance()
  public initLoading = false
  public showConfirm = false
  public observations: PodiatryObservationModel[] = []
  public observationItem: PodiatryObservationModel | any = {}
  public errorMessages: string[] = []
  public selectedDossier: any = {}
  public today = Commons.GetTodayFormatted()

  public mounted () {
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (Commons.isCachedDossierPatientValid(cachedDossier)) {
      const selectedDossier = JSON.parse(cachedDossier!) as dossierPatientModel
      this.ensureUserNavTitle(selectedDossier)
      this.getAllObservations(selectedDossier.guid)
    } else {
      this.$router.push({ name: 'patient' })
    }
  }

  public ensureUserNavTitle (patientDossier: dossierPatientModel) {
    if (patientDossier.guid) {
      Commons.updateDossierName()
    }
  }

  public getAllObservations (dossierId: string) {
    this.initLoading = true
    this.podoService.GetDossierPodoObservations(dossierId)
      .then((observations: any) => {
        this.observations = observations
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.initLoading = false
      })
  }

  public addObservationItem () {
    this.observationItem = {
      date: this.today,
      duration: this.GetDuration(),
      evaluationInitialItems: []
    }
    this.podoService.updatePodoObservationSelected(this.observationItem)
    this.$router.push('/patient/consultation/podo/observation')
  }

  public GetDuration () {
    // infirmière clinicienne -> 3
    // infirmière -> 4
    return this.userService.GroupIds.includes('3') ? 60 : (this.userService.GroupIds.includes('4') ? 45 : 0)
  }

  public editObservationItem (item: PodiatryObservationModel) {
    item.date = item.date?.substr(0, 10)
    this.observationItem = item
    this.podoService.updatePodoObservationSelected(this.observationItem)
    this.$router.push('/patient/consultation/podo/observation')
  }

  public canEditItem (item: PodiatryObservationModel) {
    return !item.isLinkedToInvoice
  }

  public canDeleteItem (item: PodiatryObservationModel) {
    return !item.isLinkedToInvoice && (this.userService.Roles.includes('NursingRecordsDelete') || Commons.GetDate(item.date)! >= Commons.TodayDate())
  }

  public confirmObservationItemDelete (item: PodiatryObservationModel) {
    this.showConfirm = true
    this.observationItem = item
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      this.initLoading = true
      const results = await this.podoService.DeletePodoObservation((this.observationItem as PodiatryObservationModel).id)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.initLoading = false
        })
      if ((results as AxiosResponse<any>)?.status === 200) {
        this.errorMessages = []
        this.getAllObservations((this.observationItem as PodiatryObservationModel).patientId)
      }
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public async generatePDFReport (item: PodiatryObservationModel) {
    item.date = item.date?.substr(0, 10)
    this.observationItem = item
    this.podoService.updatePodoObservationSelected(this.observationItem)
    this.$router.push({ name: 'PodoReport', params: { id: item.id! } })
  }
}
