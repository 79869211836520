import { Component, Vue } from 'vue-property-decorator'
import Observations from '@/views/Patients/Overview/Podo/Observations/Observations.vue'
import EvaluationsMesures from '@/views/Patients/Overview/Podo/EvaluationsMesures/EvaluationsMesures.vue'
import Photos from '@/views/Patients/Overview/Photos/Photos.vue'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { PatientService } from '@/services/patient-service'
import { Subscription } from 'rxjs'
import Commons from '@/components/shared/Helpers/commons'
import { PodoService } from '@/services/patient-podo-service'
@Component({
  components: {
    Observations,
    EvaluationsMesures,
    Photos
  }
})

export default class Podo extends Vue {
  private patientService = PatientService.getInstance()
  private podoService = PodoService.getInstance()
  private subscriptions: Subscription[] = []
  private selectedDossier!: dossierPatientModel
  public podoPhotoType = 4
  public dossierId = ''
  public tab = 0
  public tabs = ['Observations', 'Évaluations et mesures de sensibilité', 'Photos']

  public mounted () {
    this.subscriptions.push(this.patientService.dossierSelected$.subscribe(dossier => {
      this.selectedDossier = dossier
      this.dossierId = dossier.guid
    }))
    this.ensureUserNavTitle()
    this.tab = this.podoService.activeIndex
  }

  public tabChanged (index: any) {
    this.podoService.setTabIndex(index)
  }

  public ensureUserNavTitle () {
    if (this.selectedDossier?.guid) {
      Commons.updateDossierName()
    } else {
      this.$router.push('/patient')
    }
  }
}
