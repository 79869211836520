var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[_c('alert',{attrs:{"value":_vm.errorMessages.length > 0,"type":"error","canClose":true},on:{"close":_vm.hideAlert}},[_c('ul',_vm._l((_vm.errorMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0)]),_c('confirm',{attrs:{"visible":_vm.showConfirm,"title":"Supprimer cette évaluation podologique","message":"Cette action ne peut être annulée. Voulez-vous continuer et supprimer cette évaluation podologique ?"},on:{"close":function($event){return _vm.confirmDeleteCallback($event)}}}),[_c('c-btn',{staticClass:"btn-red",attrs:{"to":("/patient/consultation/podo/evaluations/" + _vm.dossierId + "/new")}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-plus ")]),_c('span',[_vm._v("Ajouter une évaluation")])],1),_c('c-data-table',{attrs:{"headers":_vm.headers,"items":_vm.evaluationsList,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll }},scopedSlots:_vm._u([{key:"item.evaluationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date-fr")(item.evaluationDate))+" ")]}},{key:"item.nextEvaluationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date-fr")(item.nextEvaluationDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-row-actions"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){return _vm.editEvaluation(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-delete",on:{"click":function($event){return _vm.displayDeleteConfirmDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])])],1)]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }